// Load Bootstrap JS
import bootstrap from "bootstrap";

// Load Styles
import "../scss/main.scss";

var myCollapsible = document.getElementById("nav-main");
myCollapsible.addEventListener("show.bs.collapse", function () {
  document.getElementsByTagName("body")[0].classList.add("nav-open");
});

myCollapsible.addEventListener("hidden.bs.collapse", function () {
  document.getElementsByTagName("body")[0].classList.remove("nav-open");
});

// addListener(document.getElementById("forgotpass"), "click", forgotpass);

// document.querySelectorAll;
// $(".genderfilter a").click(function (event) {
//   event.preventDefault();
//   $(".genderfilter a").removeClass("on");
//   $(this).addClass("on");
//   genderVal = $(this).attr("type");
//   collectionsVal = $(".collectionsfilter a.on").attr("type");
//   $(".product").each(function (index) {
//     if (genderVal == null && collectionsVal == null) {
//       $(this).fadeIn();
//     } else if (genderVal == null && $(this).attr("collection") == collectionsVal) {
//       $('.product[collection="' + collectionsVal + '"]').fadeIn();
//     } else if ($(this).attr("gender") == genderVal && collectionsVal == null) {
//       $('.product[gender="' + genderVal + '"]').fadeIn();
//     } else if ($(this).attr("gender") == genderVal && $(this).attr("collection") == collectionsVal) {
//       $(this).fadeIn();
//     } else {
//       $(this).hide(function () {});
//     }
//   });
// });
//

const filterCollection = (type, category) => {
  const products = document.querySelectorAll(".product");
  products.forEach(product => {
    if (product.dataset[category] === type || type === "") {
      product.classList.add("show");
    } else {
      product.classList.remove("show");
    }
  });
};

var collectionFilters = document.querySelectorAll("button[data-collection]");
collectionFilters.forEach(filter => {
  filter.addEventListener("click", event => {
    event.preventDefault();
    document.querySelectorAll("button[data-collection]").forEach(btn => {
      btn.closest("li").classList.remove("active");
    });

    event.target.closest("li").classList.add("active");
    filterCollection(event.target.dataset.collection, "collection");
  });
});

var genderFilters = document.querySelectorAll("button[data-gender]");
genderFilters.forEach(filter => {
  filter.addEventListener("click", event => {
    event.preventDefault();
    console.clear();
    console.log("filter clicked");
    document.querySelectorAll("button[data-gender]").forEach(btn => {
      btn.closest("li").classList.remove("active");
    });

    event.target.closest("li").classList.add("active");
    filterCollection(event.target.dataset.gender, "gender");
  });
});
